import React, { useState, useRef } from 'react';
import * as mutations from "../graphql/mutations";
import './main.css';
import logo from '../assets/logo.png';
import circles from '../assets/circles.png';
import send from '../assets/send.png';
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/data";
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

function Main() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const client = generateClient();

  const contactUs = useRef(null);

  const scrollToSection = () => {
      if (contactUs.current) {
        contactUs.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  const validateForm = () => {
    const errors = {};
    if (!isValidEmail(email)) {
        setEmail('');
    }
    if (!firstName) errors.firstName = 'First name is required';
    if (!email) errors.email = 'Email is required';
    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
        await client.graphql({
          query: mutations.createUser,
          variables: {
            input: {firstName, lastName, email, phone},
          },
        });

        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setFormErrors({});
        setError('');

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
    <div className="bg-neutral-950 flex flex-col min-h-screen">
        <header className="bg-indigo-500 text-white p-4 relative overflow-hidden">
            <img
              src={logo}
              alt="Kowaaluh"
              className=" h-12 w-auto object-contain"
            />
        </header>
      <main className="flex-1 overflow-hidden p-0">
        <div ref={contactUs} className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-neutral-950 p-8 sm:p-12">
          <div className="w-full max-w-4xl shadow-md rounded rounded-xl m-16 border border-indigo-500/50 shadow-xl shadow-indigo-500/50 bg-neutral-950 p-14">
            <div className="flex flex-col items-center">
                 <h1 className="m-2 font-light md:text-6xl max:text-6xl text-5xl text-white tracking-wide text-center">Creating <span id="spin" className="text-transparent font-normal bg-clip-text bg-gradient-to-r from-indigo-400 to-cyan-300 text-center"></span><br/>experiences in sports</h1>
                 <button onClick={scrollToSection} className="m-4 rounded-full bg-indigo-500 shadow-lg shadow-indigo-500/50 text-white text-lg py-4 px-6">Contact Us</button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-64 h-64 flex items-center justify-center bg-white shadow-md overflow-hidden">
            <img
              src={send}
              alt="Paper Airplanes"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
          <div ref={contactUs} className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-neutral-950 p-8 sm:p-12">
            <div className="w-full max-w-4xl shadow-md rounded rounded-xl m-16 border border-indigo-500/50 shadow-xl shadow-indigo-500/50 bg-neutral-950 p-14">
              <div className="flex flex-col items-center">
                <h1 className="m-2 font-light md:text-6xl text-5xl text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-cyan-300 text-center">
                Let's Connect
                </h1>
                    <form onSubmit={handleSubmit} className="mx-auto mt-4 flex w-full max-w-md flex-col gap-3">
                        {formErrors.firstName && <p style={{ textAlign: 'center', color: '#6366f1' }}>{formErrors.firstName}</p>}
                        {formErrors.email && <p style={{ textAlign: 'center', color: '#6366f1' }}>{formErrors.email}</p>}
                        {error && <p style={{ textAlign: 'center', color: '#6366f1' }}>{error}</p>}
                        <input
                          type="text"
                          name="firstName"
                          className="rounded-full border-2 border-indigo-500 py-3 px-3 focus:border-cyan-500 focus:outline-none"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <input
                          type="text"
                          name="lastName"
                          className="rounded-full border-2 border-indigo-500 py-3 px-3 focus:border-cyan-500 focus:outline-none"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                        <input
                          type="text"
                          name="email"
                          className="rounded-full border-2 border-indigo-500 py-3 px-3 focus:border-cyan-500 focus:outline-none"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                          type="text"
                          name="phone"
                          className="rounded-full border-2 border-indigo-500 py-3 px-3 focus:border-cyan-500 focus:outline-none"
                          placeholder="Phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <button className="rounded-full bg-indigo-500 shadow-lg shadow-indigo-500/50 text-white text-lg py-4 px-6">Submit</button>                    </form>
                  </div>
            </div>
          </div>
      </main>
      <footer className="bg-indigo-500 text-white p-4 flex items-center justify-center">
        <div className="flex items-center justify-center overflow-hidden">
          <img
            src={circles}
            alt="Circles"
            className="object-contain w-full h-full"
          />
        </div>
      </footer>
    </div>
    </>
  );
}

export default Main;